<template>
  <div id="ai-services_aiu_celebrity-detection">
    <service-template
      :run="run"
      :run-disabled="currentExample === null && uploadedImage === null"
      :run-success="runSuccess"
      :run-error="runError"
      :loading="loading"
      :icon="require('@/assets/images/icons/services/header/CBT.svg')"
      type="image"
      service="aiu"
    >
      <template v-slot:header>
        {{ $t('services.celebrityDetection.header') }}
      </template>
      <template v-slot:left>
        <carousel :step="1">
          <el-image
            v-for="(example, index) in examples"
            :key="index"
            :slot="`slide-${index}`"
            :class="{ 'swiper-selected': currentExample === index }"
            :src="example"
            fit="cover"
            class="example"
            @click="() => onExampleClick(index)"
          />
        </carousel>
        <upload
          :auth="isAuthenticated()"
          :file-size="2"
          :example-file="examples[currentExample]"
          :disabled="loading"
          :handle-remove="onUploadRemove"
          :handle-upload="onUploadSuccess"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="2"
          :service-name="$t('services.celebrityDetection.header')"
          :loading="loading"
          :subtitle-extra="$t(`button['Check the full list of celebrities']`)"
          :subtitle-extra-click="showFullListOfObjects"
        >
          <result-chart
            v-if="result"
            :chart-data="result"
          />
          <span v-if="result && result.length == 0">{{ $t('services.celebrityDetection.noCelebDetection') }}</span>
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import Carousel from '@/components/Carousel/Index.vue';
import Upload from '@/components/Upload/Index.vue';
import ResultChart from '@/views/components/ResultChart/Index.vue';

export default {
  name: 'ImageClassification',
  components: {
    ResultChart,
    Upload,
    Carousel,
    GeneratedResult,
    ServiceTemplate,
  },
  data: () => ({
    loading: false,
    result: null,
    currentExample: null,
    uploadedImage: null,
    examples: [],
  }),
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  async created() {
    const { default: examples } = await import('@/../public/examples/AIU/CBT/samples.json');
    this.examples = examples.map((link) => this.$consts.publicPath + link);

    this.currentExample = 0;
  },
  methods: {
    async run() {
      this.loading = true;
      const formData = new FormData();
      if (this.uploadedImage) {
        formData.append('file', this.uploadedImage);
      } else {
        const imageResponse = await this.$api.aiu.fetchImage(
          this.examples[this.currentExample],
        );
        formData.append('file', imageResponse.data);
      }
      return this.$api.aiu.celebrityDetection.detectCelebrity(formData);
    },
    runSuccess(result) {
      this.loading = false;
      this.result = Object.entries(result.data.detected_celebrity_with_probability)
        .map(([name, percentage]) => ({
          name,
          percentage: Math.round(percentage * 100),
        }))
        .sort((x, y) => y.percentage - x.percentage);
    },
    runError() {
      this.loading = false;
    },
    onExampleClick(index) {
      if (this.loading) {
        return;
      }

      this.currentExample = index;
      this.uploadedImage = null;
      this.result = null;
    },
    onUploadRemove() {
      this.result = null;
      this.uploadedImage = null;
      this.currentExample = null;
    },
    onUploadSuccess(file) {
      this.uploadedImage = file;
    },
    showFullListOfObjects() {
      const routeData = this.$router.resolve({ path: '/labels/image-celebrity-detection' });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang='scss' scoped>
#ai-services_aiu_celebrity-detection {
  background: $background-secondary;

  .example {
    height: 100%;
    cursor: pointer;
  }
}
</style>
